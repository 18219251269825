@import '../../styles/variables.scss';

.container {
  padding-top: 3rem;
}

.dashboard {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  height: 100%;
}

@media only screen and (max-width: 1200px) {
  .dashboard {
    grid-template-columns: 1fr;
  }
}

.dashboard-left {
  overflow-y: auto;
  max-height: 947px;
}

.dashboard-right {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  gap: 1rem;
}

.dashboard-list {
  max-height: 330px;
  overflow-y: auto;
}

.dashboard-list-item {
  display: block;
  padding: 0.75rem 0;
  border-bottom: 1px solid $surface-d;
  font-size: 0.9rem;

  &:first-child {
    border-top: 1px solid $surface-d;
  }
}

.dashboard-list-item .secondary-text {
  display: block;
  color: $text-color-secondary;
  text-overflow: ellipsis;
  overflow: hidden;
}
