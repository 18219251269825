@import 'src/styles/variables.scss';

.mobile-header {
  position: absolute;
  width: 100%;
  top: 0;
  background-color: white;
  border-bottom: 1px solid $primary-50;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5rem;
  padding: 0.5rem 1rem 0.5rem;
}

.drawer-toggler {
  align-items: center;
}

.drawer-toggler.collapsed {
  transform: rotate(180deg);
}

@media (min-width: 1024px) {
  .mobile-header {
    width: calc(100% - $drawer-width);
  }

  .mobile-header.collapsed {
    width: calc(100% - $drawer-collapsed-width);
  }
}
