@import '../../styles/variables.scss';

.cta-card {
  width: 100%;
  flex-direction: column;
  gap: 1rem;
  padding: 3rem;
}

.cta-card.error {
  border: 1px solid #f0a9a7;
}

.cta-card.active {
  color: $accent-color !important;
}
