@import 'src/styles/variables.scss';

.file-input-wrapper {
  position: relative;
}

.upload-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 48px;
  background-color: rgba(255, 255, 255, 0.87);
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-overlay > .p-progressbar {
  flex: 1;
}

.p-inputgroup .p-fileupload-choose {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.uploaded-file {
  font-family: 'Courier', monospace;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  border-radius: 0.5rem;
  background-color: #ffffff;
  padding: 0.75rem 0 0.75rem 1rem;
  height: 48px;
  border: 1px solid $primary-50;
}

.uploaded-file .content {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex: 1;
  min-width: 24px;
}

.uploaded-file .content svg {
  min-width: 24px;
}

.uploaded-file .filename {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.uploaded-file .remove-file-btn {
  height: 48px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.p-inputgroup .uploaded-file {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.animate {
  animation-duration: 0.5s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(.26,.53,.74,1.48);
  animation-fill-mode: backwards;
}

.animate.fade {
  animation-name: animate-fade;
  animation-timing-function: ease;
}

@keyframes animate-fade {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

