@import 'src/styles/variables.scss';

.product-image {
  padding: 1rem;
  background-color: #fff;
  object-fit: contain;
  border-radius: 0.5rem;
}

.p-menu.p-menu-overlay {
  margin-top: 0.5rem;
  min-width: fit-content;
}

.end-of-life .pi-trash {
  color: $accent-color !important;
}
