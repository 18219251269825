@import 'src/styles/variables.scss';

.profile-section {
  display: flex;
  align-items: center;
  user-select: none;
}

.user-details {
  max-width: 190px;
  overflow: hidden;
}
