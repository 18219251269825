.products-more-button {
    max-height: 40px;
}

.more-menu {
    right: 0;
    top: 40px;
    background-color: #fff !important;
    border-radius: 8px !important;
}

.more-menu-item {
    &:hover {
        background-color: #f4f4f4;
        cursor: pointer;
    }
}
