@import '../../styles/variables.scss';

.message-item {
  margin-bottom: 1.5rem;
  position: relative;
}

.message-item .message-header {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

.message-item .timestamp {
  display: block;
  font-size: 0.875rem;
  color: $text-color-secondary;
}

.message-item .delete-btn {
  margin-left: auto;
}

.message-body {
  white-space: pre-wrap;
}
