@import 'src/styles/variables.scss';

.notification-item {
  position: relative;
  display: block;
  cursor: pointer;
  transition: color 100ms ease-in;
}

.notification-item:hover .notification-title {
  color: $accent-color;
}

.notification-item:not(:last-of-type) {
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid $surface-300;
}

.notification-item .notification-title {
  display: block;
  font-weight: bold;
  max-height: 42px;
  padding-right: 1.5rem;
  margin-bottom: 0.25rem;
  overflow: hidden;
  color: $text-color;
}

.notification-item .badge {
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  background-color: $accent-color;
  border-radius: 50%;
  position: absolute;
  top: 0.5rem;
  right: 0;
}

.notification-item .notification-body {
  display: block;
  color: $text-color-secondary;
}

.notification-item .notification-timestamp {
  display: block;
  color: $text-color-secondary;
}
