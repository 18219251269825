$surface-a:#ffffff;
$surface-b:#fafafa;
$surface-c:#F4F4F4;
$surface-d:#e7e7e7;
$surface-e:#dcdcdc;
$surface-f:#c3c3c3;
$text-color:#2a2a2a;
$text-color-secondary:#71717A;
$primary-color:#2a2a2a;
$primary-color-text:#ffffff;
$accent-color: #B02017;
$success-color: #5A8C3B;
$font-family: 'LocatorWeb', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
$surface-0: #ffffff;
$surface-50: #FAFAFA;
$surface-100: #F4F4F4;
$surface-200: #e8e8e8;
$surface-300: #dedee3;
$surface-400: #A1A1AA;
$surface-500: #71717A;
$surface-600: #52525B;
$surface-700: #3F3F46;
$surface-800: #27272A;
$surface-900: #18181B;
$gray-50: #FAFAFA;
$gray-100: #F4F4F4;
$gray-200: #E4E4E7;
$gray-300: #D4D4D8;
$gray-400: #A1A1AA;
$gray-500: #71717A;
$gray-600: #52525B;
$gray-700: #3F3F46;
$gray-800: #27272A;
$gray-900: #18181B;
$content-padding:1.25rem;
$inline-spacing:0.5rem;
$border-radius:0.375rem;
$surface-ground:#FAFAFA;
$surface-section:#ffffff;
$surface-card:#ffffff;
$surface-overlay:#ffffff;
$surface-border:#e5e7eb;
$surface-hover: #F4F4F4;
$maskbg: rgba(0, 0, 0, 0.4);
$focus-ring: 0 0 0 0.2rem $surface-300;

$primary-50:#eaeaea;
$primary-100:#d4d4d4;
$primary-200:#aaaaaa;
$primary-300:#7f7f7f;
$primary-400:#555555;
$primary-500:#2a2a2a;
$primary-600:#222222;
$primary-700:#191919;
$primary-800:#111111;
$primary-900:#080808;

$teal-color: #598E88;
$teal-bg: rgba(89, 142, 136, 0.13);

$yellow-color: #bd8400;
$yellow-bg: rgba(210, 147, 0, 0.13);

$cyan-color: #286F79;
$cyan-bg: rgba(40, 111, 121, 0.13);

$indigo-color: #535497;
$indigo-bg: rgba(83, 84, 151, 0.13);

$green-color: #4c7732;
$green-bg: rgb(90, 140, 59, 0.13);

$purple-color: #7F639E;
$purple-bg: rgba(127, 99, 158, 0.13);

$blue-color: #007AA1;
$blue-bg: rgba(0, 122, 161, 0.13);

$red-color: $accent-color;
$red-bg: rgba(197, 73, 51, 0.13);

$bluegray-color: #4C5F6A;
$bluegray-bg: rgba(76, 95, 106, 0.13);

$pink-color: #AB337F;
$pink-bg: rgba(171, 51, 127, 0.13);

$darkcyan-color: #3F8C88;
$darkcyan-bg: rgba(63, 140, 136, 0.13);

// Components
$drawer-width: 20rem;
$drawer-collapsed-width: 6.25rem;
$border-radius-default: 0.5rem;
$border-radius-lg: 1rem;

// Steps
$stepper-circle-size: clamp(1rem, 5vw, 1rem);
$stepper-spacing: clamp(0.25rem, 2vw, 0.25rem);
