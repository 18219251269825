body {
  margin: 0;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-color;
  font-family: $font-family;
  font-size: 1rem;
  background-color: $surface-c;
}

a {
  color: $accent-color,
}

a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  color: $accent-color;
  transition: color, background-color 100ms ease-in;
}

h1 {
  font-weight: normal;
}

h1, h2, h3, h4, h5, h6, p {
  margin-top: 0;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2rem;
}

h2, .h2 {
  font-size: 1.75rem;
}

h3, .h3 {
  font-size: 1.5rem;
}

h4, .h4 {
  font-size: 1.25rem;
}

h5, .h5, h6, .h6 {
  font-size: 1rem;
}

.text-muted {
  color: $text-color-secondary;
}

.label-lg {
  display: block;
  font-weight: 700;
  margin-bottom: 1rem;
}

.form-help {
  display: block;
  font-size: 0.875rem;
  color: $text-color-secondary;
  margin-top: 0.25rem;
}
