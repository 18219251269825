@page {
  size: auto;
  margin: 16mm;
}

.print-header {
  display: flex;
  align-items: center;
  gap: 2rem;
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #EAEAEA;
}
