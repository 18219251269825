@font-face {
  font-family: 'LocatorWeb';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/LocatorWebRegular.eot'),
       url('./fonts/LocatorWebRegular.woff') format('woff');
}

@font-face {
  font-family: 'LocatorWeb';
  font-style: italic;
  font-weight: 400;
  src: url('./fonts/LocatorWebRegularItalic.eot'),
       url('./fonts/LocatorWebRegularItalic.woff') format('woff');
}

@font-face {
  font-family: 'LocatorWeb';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/LocatorWebMedium.eot') format('eot'),
       url('./fonts/LocatorWebMedium.woff') format('woff');
}

@font-face {
  font-family: 'LocatorWeb';
  font-style: italic;
  font-weight: 500;
  src: url('./fonts/LocatorWebMediumItalic.eot'),
       url('./fonts/LocatorWebMediumItalic.woff') format('woff');
}

@font-face {
  font-family: 'LocatorWeb';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/LocatorWebBold.eot') format('eot'),
       url('./fonts/LocatorWebBold.woff') format('woff');
}

@font-face {
  font-family: 'LocatorWeb';
  font-style: italic;
  font-weight: 700;
  src: url('./fonts/LocatorWebBoldItalic.eot'),
       url('./fonts/LocatorWebBoldItalic.woff') format('woff');
}
