@import '../../styles/variables.scss';

.authorization-card {
  width: 220px;
  height: 160px;
  display: flex;
  flex-direction: column;
  text-align: left !important;
  align-items: flex-start;
}

.authorization-card-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.authorization-card-button {
  padding: 0px;
  border: 0px !important;
}

.authorization-card h4 {
  font-size: 1rem;
  margin-bottom: 0px;
}

.authorization-card h5 {
  font-weight: normal;
  margin-bottom: 1rem;
}

.authorization-card p {
  margin-bottom: 0px;
  font-size: 0.8rem;
}

.authorization-card.error {
  border: 1px solid #f0a9a7;
}

.authorization-card.active {
  color: $accent-color !important;
}
