* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.setting-group {
  margin-bottom: 1.5rem;
}

.setting-group.bordered {
  padding-bottom: 1rem;
  border-bottom: 1px solid $primary-50;
}

.switch-input-group {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.input-inversed,
.input-inversed > .p-inputtext {
  background-color: $gray-50;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.p-inputgroup .p-button.addon {
  border-left-width: 0;
}

.border-surface {
  border: 1px solid #eaeaea;
}

.form-field-info {
  display: block;
}

.p-inline-message.p-inline-message-info.system-message,
.login-message .p-message.p-message-info {
  justify-content: space-between;
  border: solid #ffffff;
  border-width: 0 0 1px 0;
  background-color: $accent-color;
  color: #fff;
  border-radius: 0;
  width: 100%;
  font-weight: 500;
  margin: 0;
}

.p-inline-message.p-inline-message-info.system-message .p-button {
  color: #fff;
}

.login-message .p-message.p-message-info .p-message-close.p-link,
.login-message .p-message.p-message-info .p-message-icon.pi.pi-info-circle {
  color: #fff;
}
