@import 'src/styles/variables.scss';

.price_calculator {
  padding: 2rem;
  background-color: #fff;
  border: 1px solid #eaeaea;
  border-radius: $border-radius-lg;
}

.price_calculator__header,
.price_calculator__body__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.price_calculator__header {
  margin-bottom: 1rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #e0e0e0;
}

.price_calculator__header > span {
  font-size: 1.125rem;
  text-transform: uppercase;
}

.price_calculator__body__row {
  line-height: 2.5;
}

.price_calculator__body__row__value {
  font-weight: 500;
}
