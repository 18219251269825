@import 'src/styles/variables.scss';

.language-selector {
  width: 100%;
  background-color: $surface-100;
  border-color: $surface-100;
}

.language-selector.collapsed .p-dropdown-trigger {
  display: none;
}

.language-selector.collapsed .option-label {
  display: none;
}

.language-selector.collapsed .flag {
  margin-right: 0;
}

.language-selector.collapsed .country-item {
  justify-content: center;
}
