.authorization-details {
  display: grid;
  grid-template-columns: repeat(auto-fit, 220px);
  grid-gap: 10px;
  justify-items: start;
}

.add-authorization-button {
  width: 220px;
  height: 160px;
}
