@import 'src/styles/variables.scss';

main {
  padding-top: 0;
}

@media (min-width: 1024px) {

  main.drawer-open {
      margin-left: $drawer-width;
  }

  main.drawer-collapsed {
    margin-left: $drawer-collapsed-width;
  }
}
