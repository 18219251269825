.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: radial-gradient(68.68% 135.82% at 31.32% 48.29%, #FFFFFF 0%, rgba(244, 244, 244, 0) 100%), #F4F4F4;

  .login-section {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr;
    gap: 3rem;

    @media (min-width: 1024px) {
      grid-template-columns: 200px 1fr 200px;
      height: 400px;
    }

    .login-divider {
      display: none;

      @media (min-width: 1024px) {
        display: block;
      }
    }

    .logo-container {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;

      .logo {
        display: block;
        width: 110px;
        margin-bottom: 1.25rem;
      }

      .logo-alt {
        font-size: 1.25rem;
      }
    }

    .login-btn {
      display: block;
      cursor: pointer;
      user-select: none;

      .ms-logo {
        display: block;
      }
    }

    .login-btn--disabled {
      opacity: 0.6;
      pointer-events: none;
    }
  }
}
