@import 'src/styles/variables.scss';

.history-item {
  font-size: 0.9rem;
  border: 1px solid $primary-50;
  padding: 1rem;
  background-color: $surface-100;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.history-item .p-panel.p-panel-toggleable .p-panel-header {
  padding: 0;
  font-size: 0.9rem;
  background-color: transparent;
  border: none;
}

.history-item .p-panel .p-panel-header .p-panel-title {
  font-weight: 500;
}

.history-item .p-panel .p-panel-content {
  font-size: 0.9rem;
  border-left: 1px solid #ccc;
  border-bottom: 0;
  border-right: 0;
  border-radius: 0;
}

.history-item .p-panel .p-panel-content {
  background-color: transparent;
}

.history-item .change-row:not(:last-of-type) {
  margin-bottom: 1rem;
}

.history-item .old-value {
  word-break: break-all;
  color: $red-color;
}

.history-item .new-value {
  color: $green-color;
  word-break: break-all;
}
