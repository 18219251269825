@import "../../styles/variables.scss";

.stepper-wrapper {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 2rem;
}

.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 4px solid #D9D9D9;
  width: 100%;
  top: 8px;
  left: 0;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 4px solid #D9D9D9;
  width: 100%;
  top: 8px;
  left: 0;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  background: #D9D9D9;
  margin-bottom: 6px;
}

.stepper-item.active {
  font-weight: bold;
}

.stepper-item.active .step-counter {
  background-color: $accent-color;
}

.stepper-item.completed .step-counter {
  background-color: $accent-color;
}

.stepper-item.completed::after {
  border-bottom: 4px solid $accent-color;
  z-index: 3;
}

.stepper-item:first-child::before,
.stepper-item:last-child::before,
.stepper-item:last-child::after {
  content: none;
}
