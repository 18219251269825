@import '../../styles/variables.scss';

.settings-nav > .nav-list {
  list-style: none;
  padding: 0;
  overflow: hidden;
  margin-top: 0;
}

.settings-nav > .nav-list .nav-link {
  color: $text-color;
  display: block;
  font-weight: bold;
  padding: 1rem 0;
  transition: color 100ms ease-in;

  &.active {
    color: $accent-color;
  }

  &:hover {
    color: $accent-color;
  }
}
