@import 'src/styles/variables.scss';

.compare-list-item .old-value {
  word-break: break-all;
  color: $red-color;
}

.compare-list-item .new-value {
  color: $green-color;
  word-break: break-all;
}
