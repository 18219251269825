@import '../../styles/variables.scss';

.view-description {
  line-height: 2;
  color: $text-color-secondary;
  max-width: 992px;
  white-space: pre-line;
}

@media print {
  .header-toolbar {
    display: none;
  }
}
