@import 'src/styles/variables.scss';

.drawer {
  position: fixed;
  top: 0;
  left: 0;
  width: $drawer-width;
  transition: transform 150ms ease-in;
  transform: translateX(-$drawer-width);
  height: 100%;
  background-color: $surface-a;
  color: $text-color;
  padding: 2rem;
  z-index: 10;
  overflow-x: hidden;
  overflow-y: auto;
  border-right: 1px solid $primary-50;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.drawer.open {
  transform: translateX(0);
}

.drawer > .logo-container {
  display: flex;
  align-items: center;
  gap: $content-padding;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid $surface-d;
}

.drawer > .navigation > ul {
  list-style: none;
  padding: 0;
  margin: 0;
  padding: $content-padding 0;
}

.drawer > .navigation > ul:not(:last-of-type) {
  border-bottom: 1px solid $surface-d;
}

.drawer > .navigation > ul > li > a,
.drawer > .navigation > ul > li > span {
  position: relative;
  cursor: pointer;
  padding: 1rem 0;
  display: grid;
  grid-template-columns: 32px auto;
  grid-template-rows: 2.5rem;
  grid-gap: 1rem;
  align-items: center;
  color: $text-color;
  transition: color 100ms ease-in;
  overflow: hidden;
}

.drawer > .navigation > ul > li > a:hover,
.drawer > .navigation > ul > li > span:hover {
  color: $accent-color;
}

.drawer .avatar {
  margin-right: 1rem;
}

.drawer-footer {
  margin-top: auto;
  width: 100%;
}

// Collapsed drawer

.drawer.collapsed {
  width: $drawer-collapsed-width;
  transform: translateX(0);
  padding: 1.5rem;
}

.drawer.collapsed .avatar {
  margin-right: 0;
}

.drawer.collapsed .details-wrapper {
  display: none;
}

.drawer.collapsed .profile-section {
  justify-content: center;
}

.drawer.collapsed > .navigation > ul > li > a,
.drawer.collapsed > .navigation > ul > li > span {
  grid-template-columns: auto;
  justify-content: center;
}

.drawer.collapsed .drawer-footer {
  padding: 1.5rem;
}

.flag {
  width: 18px;
  background: url(../../assets/images/flags_responsive.png) no-repeat;
  background-size: 100%;
  vertical-align: middle;
  margin-right: 0.75rem;
}

.flag-en-US {
  background-position: 0 92.561983%;
}

.flag-fi-FI {
  background-position: 0 27.68595%;
}

.country-item {
  display: flex;
  align-items: center;
}
