@import '../../styles/variables.scss';

.status-indicator {
  width: 11px;
  height: 11px;
  border-radius: 50%;
}

.status-indicator.muted {
  background-color: $gray-400;
}

.status-indicator.success {
  background-color: $green-color;
}

.status-indicator.warning {
  background-color: $yellow-color;
}

.status-indicator.error {
  background-color: $red-color;
}
