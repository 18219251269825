.thumbnail {
  border: 1px solid #ccd1d8;
  border-radius: 0.5rem;
  width: 50px;
  height: 50px;
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 0.35rem;
}
