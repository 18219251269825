@import 'src/styles/variables.scss';

.nav-badge {
  position: absolute;
  top: 16px;
  left: 10px;
  background-color: $accent-color;
  color: white;
  border-radius: 50%;
  font-size: 0.7rem;
  font-weight: bold;
  padding: 0.1rem 0.5rem;
}
