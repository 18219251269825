@import 'src/styles/variables.scss';

.container,
.container-sm,
.container-lg {
  width: 100%;
  margin: 0 auto;
  padding: $content-padding;
}

@media (min-width: 576px) {
  .container,
  .container-sm,
  .container-lg {
      max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-sm,
  .container-lg {
      max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
      max-width: 1440px;
  }

  .container-sm {
      max-width: 1024px;
  }

  .container-lg {
      max-width: 1640px;
  }

  .container,
  .container-sm,
  .container-lg {
      padding: 3rem;
  }
}
